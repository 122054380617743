<script lang='ts'>
  import { link } from 'svelte-routing'

  import { API_URL } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { logger } from '@/helpers/logger'
  import { notifier } from '@/helpers/notifier'

  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import HomeSlides from '@/components/ui/HomeSlides.svelte'

  let email: HTMLInputElement
  let sent = false

  function handleSubmit () {
    fetch(API_URL + '/auth/passwordReset', {
      body: JSON.stringify({
        email: email.value
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((result) => {
        result.json().then(() => {
          notifier.success($_('auth.passwordResetSent'))
          sent = true
        }).catch(e => {
          logger.error(e)
        })
      })
      .catch(() => {
        alert($_('auth.incorrectData'))
      })

    return false
  }
</script>

<div class='auth-wrapper'>
  <HomeSlides />
  <div class='forgot-password-page'>
    <MenuNotLogged />
    <form id='forgot_pass_form' class='login-form' method='post' on:submit|preventDefault={handleSubmit}>
      <div class='login-header'>
        <h2>{$_('auth.forgotPasswordTitle')}</h2>
        {#if sent}
          <h4>{$_('auth.passwordResetSent')}</h4>
          <p class='text1'>{$_('auth.setNewPassword')}
            <a href='/verify' use:link><strong>{$_('auth.resetPassword')}</strong></a>
          </p>
        {:else}
          <p class='text1'>{$_('auth.forgotPasswordInstructions')}</p>
        {/if}
      </div>
      {#if !sent}
        <hr />
        <div class='input-data'>
          <label for='email'>{$_('auth.email')}</label>
          <input
            bind:this={email}
            id='email'
            class='email'
            placeholder={$_('auth.emailPlaceholder')}
            required
            type='email'
            name='email'
          />
        </div>
        <button type='submit'>{$_('auth.resetPassword')}</button>
        <p class='myAccount'>{$_('auth.noAccess')}
          <a href='/' use:link><strong>{$_('auth.contactSupport')}</strong></a> <!-- todo - add an appropriate link -->
        </p>
      {/if}
    </form>
    <p class='my-account'>{$_('auth.alreadyHaveAccount')}
      <a href='/login' use:link><strong>{$_('auth.login')}</strong></a>
    </p>
  </div>
</div>

<style lang='scss'>
  hr {
    visibility: hidden;
  }

  label {
    font: var(--Medium-300);
    color: var(--Gray-Darker);
  }

  a {
    text-align: center;
  }
</style>
